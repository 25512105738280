import zod from 'zod'

import {
  pagarmeBillingType,
  pagarmeInterval,
  PagarmePaymentMethodEnum,
} from './variables'

export const planItemSchema = zod.object({
  name: zod.string().min(3, 'Deve ter ao menos 3 letras'),
  quantity: zod.number().min(1, 'Deve ser ao menos uma unidade'),
  pricing_scheme: zod.object({
    price: zod
      .string()
      .min(0, 'Não pode ser negativo')
      .transform((v) => +v * 100),
  }),
})

export const createPlanSchema = zod.object({
  name: zod.string().min(3, 'Deve ter ao menos 3 letras'),
  payment_methods: zod
    .enum(PagarmePaymentMethodEnum)
    .array()
    .min(1, 'Selecione ao menos um valor'),
  interval: zod.enum(pagarmeInterval, {
    errorMap: () => ({ message: 'Selecione um valor' }),
  }),
  interval_count: zod.number().min(1, 'Valor não pode ser inferior a 1'),
  billing_type: zod.enum(pagarmeBillingType),
  items: zod.array(planItemSchema).min(1, 'Deve haver ao menos 1 item'),
  licenseAmount: zod.number().min(1, 'Dever ser maior que zero'),
  visible: zod.boolean(),
  code: zod.string().optional(),
})

export const patchPlanSchema = zod.object({
  visible: zod.boolean(),
  code: zod.string().optional(),
})

export const updatePaymentCardSchema = zod.object({
  number: zod
    .string({ required_error: 'Obrigatório' })
    .min(18, 'Deve ter no mínimo 15 números')
    .max(19, 'Deve ter ao máximo 16 números')
    .transform((v) => v.replaceAll(/\D/g, '')),
  holder_name: zod
    .string({ required_error: 'Obrigarório' })
    .min(3, 'Obrigatório'),
  exp_month: zod
    .number({ required_error: 'Obrigatório' })
    .min(1, 'valor inválido')
    .max(12, 'Valor inválido'),
  exp_year: zod
    .number()
    .min(new Date().getFullYear() % 2000, 'Valor incorreto'),
  cvv: zod
    .string({ required_error: 'Obrigatório' })
    .min(3, 'Deve ter no mínimo 3 números')
    .max(4, 'Deve ter no máximo 4 números'),
  billing_address: zod.object({
    line_1: zod.string({ required_error: 'Obrigatório' }).min(3, 'Obrigarório'),
    line_2: zod.string({ required_error: 'Obrigatório' }).optional().nullable(),
    zip_code: zod
      .string()
      .min(9, 'Deve ter no mínimo 8 números')
      .transform((v) => v.replaceAll(/\D/g, '')),
    city: zod.string({ required_error: 'Obrigatório' }).min(3, 'Obrigarório'),
    state: zod
      .string({ required_error: 'Obrigatório' })
      .max(2, 'RJ, BA, SP')
      .toUpperCase(),
    country: zod.string().max(2).toUpperCase(),
  }),
})

export type PagarmePlanItem = zod.infer<typeof planItemSchema>
export type CreatePlanDTO = zod.infer<typeof createPlanSchema>
export type PatchPlanDTO = zod.infer<typeof patchPlanSchema>
export type UpdatePaymentCardDTO = zod.infer<typeof updatePaymentCardSchema>
