import { zodResolver } from '@hookform/resolvers/zod'
import { ChangeEvent, useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { MaskedInput, TextInput } from '~/components/TextInput'
import {
  UpdatePaymentCardDTO,
  updatePaymentCardSchema,
} from '~/modules/sales/schemas'
import { updateSubscriptionPaymentCard } from '~/modules/sales/services'
import { insertToast } from '~/modules/toasts/services'
import { requestAddressViaCEP } from '~/modules/viacep/services'
import { debounce } from '~/utils/debounce'

import { subscriptionsContext } from '../../..'

type Props = {
  onSuccess: () => void
}

const debouncer = debounce()

export const UpdateCardForm: React.FC<Props> = ({ onSuccess }) => {
  const { updateSubscription } = useContext(subscriptionsContext)
  const { handleSubmit, register, control, formState, setValue } =
    useForm<UpdatePaymentCardDTO>({
      defaultValues: {
        billing_address: {
          country: 'BR',
        },
      },
      resolver: zodResolver(updatePaymentCardSchema),
    })

  const submit = useCallback(
    (payload: UpdatePaymentCardDTO) => {
      console.log(payload)
      updateSubscriptionPaymentCard(payload).then((data) => {
        if (data) {
          insertToast({
            duration: 4,
            message: 'Cartão de cobrança atualizado.',
            title: 'Sucesso!',
          })
          updateSubscription(data)
          onSuccess && onSuccess()
        }
      })
    },
    [onSuccess, updateSubscription],
  )

  return (
    <form className='flex flex-col gap-4' onSubmit={handleSubmit(submit)}>
      <div className='flex flex-col gap-2'>
        <Controller
          control={control}
          name='number'
          render={({ field }) => (
            <MaskedInput
              mask='creditCard'
              maxLength={19}
              label='Número do cartão'
              value={field.value}
              onChange={field.onChange}
              error={formState.errors.number?.message}
            />
          )}
        />
        <TextInput
          label='Nome completo (como está no cartão)'
          {...register('holder_name')}
          error={formState.errors?.holder_name?.message}
        />
        <div className='flex gap-3'>
          <Controller
            control={control}
            name='exp_month'
            render={({ field }) => (
              <MaskedInput
                mask='integer'
                label='Mês de vencimento'
                placeholder='2'
                maxLength={2}
                inputMode='decimal'
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.exp_month?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='exp_year'
            render={({ field }) => (
              <MaskedInput
                mask='integer'
                label='Ano de vencimento'
                placeholder='32'
                maxLength={2}
                inputMode='decimal'
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.exp_year?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='cvv'
            render={({ field }) => (
              <MaskedInput
                mask='numberString'
                label='Código de segurança'
                placeholder='CVV'
                maxLength={4}
                inputMode='decimal'
                value={field.value}
                onChange={field.onChange}
                error={formState.errors.cvv?.message}
              />
            )}
          />
        </div>

        <div className='flex flex-col gap-2'>
          <p>
            <strong>Endereço de cobrança</strong>
          </p>

          <div className='flex flex-col bg-slate-50 p-4 rounded-lg border'>
            <Controller
              control={control}
              name='billing_address.zip_code'
              render={({ field }) => (
                <MaskedInput
                  mask='zipCode'
                  label='CEP'
                  placeholder='00000-000'
                  value={field.value}
                  error={formState.errors.billing_address?.zip_code?.message}
                  onChange={(v: string) => {
                    if (v.length === 9) {
                      debouncer(() => {
                        requestAddressViaCEP(v.replace(/\D/g, '')).then(
                          (data) => {
                            if (data) {
                              setValue('billing_address.city', data.localidade)
                              setValue('billing_address.state', data.uf)
                              setValue(
                                'billing_address.line_1',
                                data.logradouro,
                              )
                              setValue(
                                'billing_address.line_2',
                                data.complemento,
                              )
                            }
                          },
                        )
                      })
                    }
                    field.onChange(v)
                  }}
                />
              )}
            />
            <a
              href='http://www.buscacep.correios.com.br/sistemas/buscacep/'
              rel='noopener noreferrer'
              target='_blank'
              className='underline mb-2 text-sm text-gpa-blue-500 hover:text-blue-800'
            >
              Não sei meu CEP
            </a>

            <TextInput
              label='Logradouro'
              {...register('billing_address.line_1')}
              error={formState.errors.billing_address?.line_1?.message}
            />
            <TextInput
              label='Complemento'
              {...register('billing_address.line_2')}
              error={formState.errors.billing_address?.line_2?.message}
            />
            <div className='flex gap-3'>
              <TextInput
                label='Cidade'
                placeholder='Niterói'
                {...register('billing_address.city')}
                error={formState.errors.billing_address?.city?.message}
              />
              <Controller
                control={control}
                name='billing_address.state'
                render={({ field }) => (
                  <TextInput
                    label='Estado'
                    maxLength={2}
                    placeholder='RJ'
                    value={field.value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e.target.value.toUpperCase())
                    }}
                    error={formState.errors.billing_address?.state?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        disabled={formState.isSubmitting}
        className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
        text-white hover:bg-[rgb(48,137,226)] transition-colors
          duration-200 whitespace-nowrap active:opacity-70 disabled:opacity-70'
      >
        {formState.isSubmitting ? 'Atualizando...' : 'Atualizar cartão'}
      </button>
    </form>
  )
}
