import { useCallback, useRef } from 'react'

import PicturePlaceholder from '~/assets/userPlaceholder.webp'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'

export const StudentTag: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const imgRef = useRef<HTMLImageElement>(null)

  const handleProfilePicError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.src = PicturePlaceholder
    }
  }, [])

  return (
    <div className='flex gap-3 items-center'>
      <div className='h-14 w-14 rounded-full overflow-hidden'>
        <img
          ref={imgRef}
          src={PicturePlaceholder}
          alt='face'
          loading='eager'
          className='h-full w-full object-contain'
          onError={handleProfilePicError}
        />
      </div>
      <div>
        <p className='text-base'>
          {evaluation?.student.name || '-'}&nbsp; &bull; &nbsp; Idade:{' '}
          {computeAgeService(evaluation?.student.birthday)}
        </p>
        <p className='text-xs'>
          {evaluation?.student.email || '-'}&nbsp;|&nbsp;
          {evaluation?.student.phoneNumber || '-'}
        </p>
      </div>
    </div>
  )
}
