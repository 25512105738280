import { createContext, useCallback, useEffect, useState } from 'react'

import { getPersonalTrainerSubscription } from '~/modules/sales/services'
import { Subscription } from '~/modules/sales/types'

import { LatestSubscription } from './components/LatestSubscription'
import { NoSubscription } from './components/NoSubscription'

type SubscriptionsContextData = {
  subscription?: Subscription
  updateSubscription: (payload: Subscription) => void
}

export const subscriptionsContext = createContext<SubscriptionsContextData>(
  {} as SubscriptionsContextData,
)

export function Subscriptions() {
  const [subscription, setSubscription] = useState<Subscription>()
  const [loading, setLoading] = useState<boolean>(true)

  const updateSubscription = useCallback((payload: Subscription) => {
    setSubscription(payload)
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    getPersonalTrainerSubscription(abortController).then((data) => {
      if (data) {
        setSubscription(data)
      }
      setLoading(false)
    })
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <subscriptionsContext.Provider
      value={{
        subscription,
        updateSubscription,
      }}
    >
      <div className='p-5 flex flex-col flex-1'>
        <div className='max-w-[860px] w-full mx-auto flex flex-col gap-4'>
          {loading ? (
            <div
              className='h-10 w-10 border-[8px] rounded-full
            border-r-gpa-blue-50 border-gpa-blue-500 animate-spin
              mx-auto'
            ></div>
          ) : null}
          {!loading && (!subscription || subscription.status === 'canceled') ? (
            <NoSubscription />
          ) : null}

          <h3 className='font-semibold'>Suas assinaturas</h3>

          {subscription && !loading ? <LatestSubscription /> : null}
        </div>
      </div>
    </subscriptionsContext.Provider>
  )
}
