import { useCallback, useContext, useState } from 'react'
import { CgGym } from 'react-icons/cg'

import { subscriptionsContext } from '../..'
import { SubscriptionModal } from './SubscriptionModal'

export const LatestSubscription: React.FC = () => {
  const { subscription } = useContext(subscriptionsContext)
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return subscription ? (
    <>
      <div className='flex border-2 bg-slate-50 bg-opacity-30 rounded-xl overflow-hidden'>
        <div className='h-full w-1.5 bg-gpa-blue-500'></div>
        <div className='flex py-4 pl-2 text-gpa-blue-500'>
          <CgGym size={28} />
        </div>
        <div className='flex flex-col gap-4 p-4 flex-1 items-start'>
          <div className='flex justify-between items-center w-full'>
            <h2 className='font-semibold text-slate-800t'>
              {subscription.metaData.planName}
            </h2>
            {subscription.status === 'canceled' ? (
              <span className='px-2 py-1 text-red-600 bg-gpa-red bg-opacity-20 rounded-md'>
                inativa
              </span>
            ) : null}
          </div>
          <p>
            Aqui você pode ver detalhes do seu plano, cancelá-lo e atuar dados
            do cartão de pagamento
          </p>

          <div className='flex items-center justify-between w-full'>
            <button
              type='button'
              className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
            text-white hover:bg-[rgb(48,137,226)] transition-colors
            duration-200'
              onClick={handleOpen}
            >
              Ver detalhes
            </button>
            {subscription.status === 'canceled' ? (
              <p className='ml-auto'>
                Acesso disponível até{' '}
                {new Date(subscription.validThrough).toLocaleDateString()}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <SubscriptionModal visible={open} onClose={handleClose} />
    </>
  ) : null
}
