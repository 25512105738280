import axios, { AxiosError } from 'axios'

import { ViaCEPResponse } from './types'

export async function requestAddressViaCEP(cep: string) {
  return axios
    .get<ViaCEPResponse>(`https://viacep.com.br/ws/${cep}/json/`)
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e.response?.data)
    })
}
