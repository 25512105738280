import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { insertToast } from '../toasts/services'
import { CreatePlanDTO, PatchPlanDTO, UpdatePaymentCardDTO } from './schemas'
import {
  PagarmeInterval,
  PagarmeInvoiceStatus,
  PagarmeListInvoicesDTO,
  PagarmeListPlanDTO,
  PagarmeListDTO,
  PagarmeListSubscriptionsDTO,
  PagarmePaymentMethod,
  PagarmePlan,
  PagarmeSubscriptionStatus,
  PlanPaymentLink,
  LocalPlan,
  PlanItem,
  Subscription,
  PagarmeInvoice,
} from './types'

export async function getPlans(
  params: PagarmeListPlanDTO,
  abortSignal?: AbortSignal,
) {
  return api
    .get<PagarmeListDTO<LocalPlan>>('/admin/sales/plans', {
      signal: abortSignal,
      params,
    })
    .then(({ data }) => {
      if (data.data) {
        return data
      }
    })
    .catch((e: AxiosError) => {
      console.log(e)
    })
}

export async function getAvailablePlans(controller: AbortController) {
  return api
    .get<LocalPlan[]>('/personal-trainers/plans', {
      signal: controller.signal,
    })
    .then(({ data }) => data)
    .catch((e) => {
      console.log(e)
    })
}

export async function getDiscountPlan(code: string) {
  return api
    .get<LocalPlan>(`/personal-trainers/plans/code/${code}`)
    .then(({ data }) => data)
    .catch(() => null)
}

export async function getPersonalTrainerSubscription(
  abortCotnroller: AbortController,
) {
  return api
    .get<Subscription>('/personal-trainers/subscriptions', {
      signal: abortCotnroller.signal,
    })
    .then(({ data }) => data)
    .catch((e) => {
      console.log(e)
    })
}

export async function getSubscriptions(
  params: PagarmeListSubscriptionsDTO,
  abortSignal?: AbortSignal,
) {
  return api
    .get<PagarmeListDTO<Subscription>>('/admin/sales/subscriptions', {
      signal: abortSignal,
      params,
    })
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e)
    })
}

export async function getInvoices(
  params: PagarmeListInvoicesDTO,
  abortSignal?: AbortSignal,
) {
  return api
    .get<PagarmeListDTO<PagarmeInvoice>>('/admin/sales/invoices', {
      signal: abortSignal,
      params,
    })
    .then(({ data }) => {
      if (data.data) {
        return data
      }
    })
    .catch((e: AxiosError) => {
      console.log(e)
    })
}

export async function patchPlan(planId: string, payload: PatchPlanDTO) {
  return api
    .patch<LocalPlan>(`/admin/sales/plans/${planId}`, payload)
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e)
    })
}

export async function deletePlan(planId: string, abortSignal?: AbortSignal) {
  return api
    .delete<LocalPlan>(`/admin/sales/plans/${planId}`, {
      signal: abortSignal,
    })
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e?.response?.data)
    })
}

export async function deleteSubscription(
  subscriptionId: string,
  abortSignal?: AbortSignal,
) {
  return api
    .delete(`/admin/sales/subscriptions/${subscriptionId}`, {
      signal: abortSignal,
    })
    .then((data) => data.status === 200)
    .catch((e: AxiosError) => {
      console.log(e?.response?.data)
      return false
    })
}

export async function deleteInvoice(
  invoiceId: string,
  abortSignal?: AbortSignal,
) {
  return api
    .delete(`/admin/sales/invoices/${invoiceId}`, {
      signal: abortSignal,
    })
    .then((data) => data.status === 200)
    .catch((e: AxiosError) => {
      console.log(e?.response?.data)
      return false
    })
}

export async function createPlan(payload: CreatePlanDTO) {
  return api
    .post<PagarmePlan>('/admin/sales/plans', payload)
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e?.response?.data)
    })
}

export async function createPaymentLink(
  planId: string,
  accepted_payment_methods: PagarmePaymentMethod[],
) {
  return api
    .post<PlanPaymentLink>(`/admin/sales/plans/payment-link/${planId}`, {
      accepted_payment_methods,
    })
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e?.response?.data)
    })
}

export async function cancelSubscription(id: string) {
  return api
    .delete<Subscription>(`/personal-trainers/subscriptions/${id}/cancel`)
    .then(({ data }) => data)
    .catch((e: AxiosError) => {
      console.log(e.response?.data)
    })
}

export async function updateSubscriptionPaymentCard(
  payload: UpdatePaymentCardDTO,
) {
  return api
    .patch<Subscription>('/personal-trainers/subscriptions/card', payload)
    .then(({ data }) => data)
    .catch((e: AxiosError<APIError>) => {
      console.log(e.response?.data)
      insertToast({
        duration: 4,
        message: e.response?.data?.message,
        title: 'Erro!',
        type: 'error',
      })
    })
}

export function numberToBRL(number: number) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return formatter.format(number)
}

export function sumTotalItemsValue(items: PlanItem[]) {
  let total = 0
  for (let i = 0; i < items.length; i++) {
    total += items[i].pricing_scheme.price * items[i].quantity
  }
  return total
}

export function translatePaymentMethods(
  paymentMethods: PagarmePaymentMethod[],
) {
  return paymentMethods.map((p) => {
    switch (p) {
      case 'boleto':
        return 'boleto'
      case 'debit_card':
        return 'cartão de débito'
      case 'credit_card':
      default:
        return 'cartão de crédito'
    }
  })
}

export function translateSubscriptionStatus(status: PagarmeSubscriptionStatus) {
  switch (status) {
    case 'canceled':
      return 'cancelada'
    case 'future':
      return 'futura'
    case 'failed':
      return 'falha'
    case 'active':
    default:
      return 'ativa'
  }
}

export function translateInvoiceStatus(status: PagarmeInvoiceStatus) {
  switch (status) {
    case 'canceled':
      return 'cancelada'
    case 'pending':
      return 'pendente'
    case 'failed':
      return 'falha'
    case 'paid':
    default:
      return 'paga'
  }
}

export function translatePaymentInterval(
  interval: PagarmeInterval,
  intervalCount: number,
) {
  switch (interval) {
    case 'day':
      return intervalCount === 1
        ? 'uma vez a cada dia'
        : `uma vez a cada ${intervalCount} dias`
    case 'week':
      return intervalCount === 1
        ? 'uma vez a cada semana'
        : `uma vez a cada ${intervalCount} semanas`
    case 'year':
      return intervalCount === 1
        ? 'uma vez a cada ano'
        : `uma vez a cada ${intervalCount} anos`
    case 'month':
      return intervalCount === 1
        ? 'uma vez a cada mês'
        : `uma vez a cada ${intervalCount} meses`
    default:
  }
}
