import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import zod from 'zod'

import { TextInput } from '~/components/TextInput'
import { getDiscountPlan } from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'
import { insertToast } from '~/modules/toasts/services'

import { PlanModal } from './PlanModal'

const schema = zod.object({
  code: zod
    .string({ required_error: 'Preencha o campo do cupom' })
    .min(1, 'Preencha o campo do cupom'),
})

type FormData = zod.infer<typeof schema>

export const DiscountField: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [plan, setPlan] = useState<LocalPlan>()
  const { handleSubmit, control, setValue, formState } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
    setPlan(undefined)
  }, [])

  const submit = useCallback(
    async (payload: FormData) => {
      setLoading(true)
      const data = await getDiscountPlan(payload.code)
      if (data) {
        setPlan(data)
        setShowModal(true)
        setValue('code', '')
      } else {
        insertToast({
          duration: 8,
          type: 'error',
          title: 'Falha',
          message: 'Nenhuma oferta encontrada com esse cupom',
        })
      }
      setLoading(false)
    },
    [setValue],
  )

  return (
    <div className='flex flex-col gap-2 p-6 rounded-md bg-white'>
      <h2 className='text-lg font-semibold'>Cupom de desconto</h2>
      <form className='flex items-start gap-3' onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name='code'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              error={formState.errors.code?.message}
            />
          )}
        />
        <button
          disabled={loading}
          className='min-h-[40px] px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
            text-white hover:bg-[rgb(48,137,226)] transition-colors
              duration-200'
          // onClick={handleOpen}
        >
          {loading ? (
            <div
              className='h-4 w-4 flex rounded-full border-2
              border-l-[#ffffff55] border-white animate-spin'
            ></div>
          ) : (
            'Aplicar cupom'
          )}
        </button>
      </form>

      <p>
        Se você possui um cupom de desconto, aplique-o aqui para receber uma
        oferta especial.
      </p>

      {plan ? (
        <PlanModal visible={showModal} plan={plan} onClose={handleCloseModal} />
      ) : null}
    </div>
  )
}
