import { PropsWithChildren, useContext } from 'react'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'
import { numberToBRL, sumTotalItemsValue } from '~/modules/sales/services'

import { subscriptionsContext } from '../..'
import { CancelButton } from './CancelButton'
import { UpdatePaymentCard } from './UpdatePaymentCard'

type Props = {
  visible: boolean
  onClose: () => void
}

export const SubscriptionModal: React.FC<PropsWithChildren<Props>> = ({
  visible,
  onClose,
  children,
}) => {
  const { subscription } = useContext(subscriptionsContext)
  return (
    <Modal visible={visible}>
      <div className='flex justify-between items-center mb-6'>
        <h2 className='text-xl font-medium'>Assinatura</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={onClose}
        >
          <FiX size={24} />
        </button>
      </div>

      <div className='flex flex-col gap-1 mb-8'>
        <div className='flex justify-between items-center'>
          <p>
            <strong>{subscription?.metaData.planName}</strong>
          </p>

          <p>
            Quantidade de licenças: {subscription?.metaData?.licenseAmount ?? 0}
          </p>
        </div>
        <div className='flex gap-4'>
          <p>
            <span className='text-2xl'>&bull;</span>
            <span className='font-semibold'>Início</span>:{' '}
            {new Date(subscription?.createdAt ?? '').toLocaleDateString()}
          </p>
          <p>
            <span className='text-2xl'>&bull;</span>
            <span className='font-semibold'>Data da Renovação</span>:{' '}
            {new Date(subscription?.validThrough ?? '').toLocaleDateString()}
          </p>
        </div>
      </div>

      <p className='mb-2 font-semibold'>Produtos da assinatura:</p>
      <div className='flex w-full p-3 rounded-md border border-gpa-blue-50 overflow-x-auto'>
        <table className='table-fixed w-full bg-gpa-blue-50 min-w-[500px]'>
          <thead>
            <tr className='text-left'>
              <th>Nome</th>
              <th>Quantidade</th>
              <th>Preço unitário</th>
              <th>Preço total</th>
            </tr>
          </thead>
          <tbody>
            {subscription?.metaData.items.map((i) => (
              <tr key={i?.id} className='even:bg-slate-100 odd:bg-white'>
                <td>{i.name}</td>
                <td>{i.quantity}</td>
                <td>{numberToBRL(i.pricing_scheme.price / 100)}</td>
                <td>
                  {numberToBRL((i.quantity * i.pricing_scheme.price) / 100)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className='mb-4 text-right mt-1'>
        <strong>Total: </strong>
        {numberToBRL(
          sumTotalItemsValue(subscription?.metaData?.items ?? []) / 100,
        )}
      </p>

      <h3 className='text-lg font-semibold text-slate-800 mb-2'>
        Editar Cartão
      </h3>
      <div className='flex justify-between items-center p-2 rounded-lg border-2 bg-slate-50'>
        <div className='flex flex-col gap-3'>
          <p>
            Atualize aqui os dados de cobraça para um cartão novo, caso seja
            necessário.
          </p>
          <p>
            <b>Cartão atual</b>: {subscription?.metaData.cardNumber}
          </p>
        </div>

        <UpdatePaymentCard />
      </div>

      {children}

      {subscription?.status === 'canceled' ? null : (
        <CancelButton onCancel={onClose} />
      )}
      <button
        type='button'
        className='w-full px-3 py-2  rounded-lg font-semibold
            text-gpa-blue-500 hover:bg-slate-50 transition-colors
              duration-200 mt-5'
        onClick={onClose}
      >
        Fechar
      </button>
    </Modal>
  )
}
